import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 Error" description="Oops. Page not found." />

    <div style={{ display: "grid", background: "#000", height: "300px" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          maxHeight: 400,
        }}
        layout="fullWidth"
        src="../images/header/storage-assurance-hero-bg.jpg"
        alt="Storage Assurance"
        placeholder="tracedSVG"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <h2 className="text-white text-5xl md:text-6xl font-extrabold text-center uppercase p-6">
          Page Not Found
        </h2>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage